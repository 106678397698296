import React from "react"
import {
  StencilCircles,
  Article,
  Heading,
  Paragraph,
  StackedTriangles,
} from "../components"

const AboutMe: React.FC = () => {
  return (
    <Article>
      <StencilCircles />
      <Heading>About Me</Heading>
      <Paragraph>
        I'm a full stack developer with over 7 years of experience building high
        quality web applications.
      </Paragraph>
      <Paragraph>
        I have worked in companies - both small and large - where I have led
        teams, won awards, and spearheaded business critical projects.
      </Paragraph>
      <Paragraph>
        In my work as a freelance consultant and developer, I was usually
        responsible for every aspect of a project, from the front end code, to
        the database layer, to CI/CD pipelines. As a result, I developed a very
        broad range of expertise, and a keen understanding of how to deliver
        results efficiently, without compromising quality.
      </Paragraph>
      <Paragraph>
        Over the years, I've earned a reputation for going the extra mile to
        help others. I think of my coworker's success as my own, and I go out of
        my way to promote a culture of mutual aid and encouragement.
      </Paragraph>
      <Paragraph>
        Due to the wide breadth of my experience, I can step in to virtually any
        role on any team and immediately have a high impact. I've filled in for
        engineering managers, project managers, and technical project managers.
        I've taken on the role of a site reliability engineer, devops engineer,
        front end developer, backend developer, database administrator,
        designer; if it needs to get done, I get it done.
      </Paragraph>
      <Paragraph>
        I'm always interested in meeting new people and finding out about
        projects. So if you've got something you think I might be interested in,
        please contact me.
      </Paragraph>
    </Article>
  )
}

export default AboutMe
